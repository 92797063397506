import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from 'moment';
import 'moment/locale/it';

export const CardBigHome = ({ notizia }) =>  {
    moment.locale('it');
    return (
        <Link to={`/notizia/${notizia.slug}`} className="oratorio--block oratorio--group oratorio--relative oratorio--transition oratorio--ease-out active:oratorio--opacity-75 oratorio--overflow-hidden oratorio--mb-2.5">
            <div className="">
                <LazyLoadImage
                    className="oratorio--shadow-lg oratorio--mb-2 oratorio--block oratorio--transform oratorio--transition oratorio--ease-out *group-hover:oratorio--scale-110 oratorio--object-cover"
                    src={notizia["_embedded"]["wp:featuredmedia"][0].source_url}
                    alt={notizia.title.rendered}
                />
                <div className="oratorio--space-y-2 oratorio--uppercase oratorio--font-bold oratorio--text-sm lg:text-base">
                    {notizia.tematiche.map((item, index) => (
                        <span key={index}>{item.name}</span>
                    ))}
                </div>
                <h1 className="oratorio--text-3xl lg:oratorio--text-4xl oratorio--font-serif oratorio--mb-2 oratorio--text-left *oratorio--uppercase oratorio--font-bold" dangerouslySetInnerHTML={{ __html: notizia.title.rendered}}></h1>
                <p className="*oratorio--mb-4 oratorio--uppercase oratorio--text-left"><strong>{moment(notizia["date"]).format('DD MMMM YYYY')}</strong> {moment(notizia["date"]).format('HH:ss')}</p>
            </div>
        </Link>
    );
};
