import React, { useState, useEffect, useRef } from 'react';
import { Skeleton, SpeedDial, SpeedDialIcon, SpeedDialAction, Fab } from '@mui/material';
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import { API_URL, DEFAULT_TITLE_SUFIX } from '../../config';
import moment from 'moment';
import 'moment/locale/it';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';

const Corso = () => {
  const containerRef = useRef(null);
  const [corso, setCorso] = useState(null);
  const [loading, setLoading] = useState(true); // Aggiunto stato di caricamento
  const { slug } = useParams();

  moment.locale('it');

  useEffect(() => {
    setLoading(true); // Impostato loading a true prima del fetch

    fetch(API_URL + 'oratoriosangirolamo/v1/corsi?slug=' + slug + '&_embed')
      .then(response => response.json())
      .then(data => {
        setCorso(data);
        setLoading(false);
        const container = containerRef.current;
        NativeFancybox.bind(container, ".wp-block-image img", {});
      });
  }, [slug]);

  useEffect(() => {
    return () => {
      const container = containerRef.current;
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  }, []);

  return (
    <div>
      {loading ? (
        <>
        <div>
            <Skeleton className="oratorio--shadow-lg oratorio--mb-4 hidden-lg-up" variant="rectangular" width="100%" height={512} />
        </div>
        <main className="oratorio--pt-3 oratorio--pb-2.5">
            <div className="oratorio--w-full oratorio--mb-3">
                <div className="oratorio--container oratorio--mx-auto *oratorio--text-center +lg:oratorio--text-left">
                    <h6 className="oratorio-text-base lg:oratorio--text-lg oratorio--uppercase oratorio--font-bold oratorio--mb-0.5"><Skeleton width="35%" /></h6>
                    <h1 className="oratorio--text-3xl md:oratorio--text-4xl lg:oratorio--text-5xl oratorio--font-serif oratorio--font-bold oratorio--mb-2"><Skeleton width="60%" /></h1>
                </div>
            </div>
            <section className="oratorio--container">
                <Skeleton variant="rectangular" width="100%" height={512} />
            </section>
        </main>
          </>
      ) : (
        <>
          <Helmet>
            {generateHeadTags({
            title: corso[0].title.rendered+DEFAULT_TITLE_SUFIX,
            description: 'Le Figlie di Maria Ausiliatrice, nel solco dello spirito salesiano e seguendo l&#039;esempio luminoso di Don Bosco e di Madre Maria Mazzarello, sono attive nella comunità del quartiere periferico di San Girolamo a Bari. La nostra missione è centrata sulla formazione dei bambini e il recupero dei giovani, con l&#039;obiettivo di farne &quot;Buoni cristiani e onesti cittadini&quot;, seguendo l&#039;ispirazione di Don Bosco.',
            keywords: 'Oratorio San Girolamo Bari, Figlie di Maria Ausiliatrice, Salesiane Don Bosco Maria Ausiliatrice, Attività Oratorio Bari, Comunità Salesiana Bari, Educazione Salesiana, Eventi Oratorio San Girolamo, Iniziative Educativo-Ricreative, Formazione Spirituale Bari, Servizio Giovanile Don Bosco, Impegno Sociale e Solidarietà, Oratorio e Cultura Salesiana, Crescita Personale e Spirituale, Incontri Giovanili Bari, Oratorio per Bambini e Ragazzi, Valori Salesiani Bari',
            openGraph: {
                type: 'article',
                title: corso[0].title.rendered,
                image: corso[0].campi.immagine_card.url,
            },
            })}
          </Helmet>
          <picture>
            <source media="(max-width: 767px)" srcSet={`https://wsrv.nl/?url=`+corso[0].campi.immagine_card.url+`&w=2460&h=1386&fit=cover&a=center&il&output=webp&f=80`} />
            <source media="(min-width: 768px)" srcSet={`https://wsrv.nl/?url=`+corso[0].campi.immagine_card.url+`&w=1538&h=585&fit=cover&a=center&il&output=webp&f=80`} />
            <img loading="lazy" src={`https://wsrv.nl/?url=`+corso[0].campi.immagine_card.url+`&w=2460&h=1386&fit=cover&a=center&il&output=webp&f=80`} alt="Immagine" />
          </picture>
            <main className="oratorio--pt-3 oratorio--pb-2.5" ref={containerRef}>
                <div className="oratorio--w-full oratorio--mb-3">
                    <div className="oratorio--container oratorio--mx-auto *oratorio--text-center +lg:oratorio--text-left">
                        <h6 className="oratorio-text-base lg:oratorio--text-lg oratorio--uppercase oratorio--font-bold oratorio--mb-0.5">{corso[0].campi.tipologia.label}</h6>
                        <h1 className="oratorio--text-3xl md:oratorio--text-4xl lg:oratorio--text-5xl oratorio--font-serif oratorio--font-bold oratorio--mb-2" dangerouslySetInnerHTML={{ __html: corso[0].title.rendered }}></h1>
                    </div>
                </div>
                <section className="oratorio--container">
                    <div className="oratorio--prose oratorio--prose-yellow-700 oratorio--prose-a:text-yellow-700 oratorio--max-w-none md:oratorio--prose-xl *oratorio--text-gray-800 oratorio--mx-auto prose-headings:oratorio--font-serif prose-headings:oratorio--font-bold *prose-headings:oratorio--uppercase prose-headings:oratorio--mb-1" dangerouslySetInnerHTML={{ __html: corso[0].content.rendered }}></div>
                </section>
            </main>
            {navigator?.share ? (
            <Fab color="primary" aria-label="Condividi" sx={{ position: 'fixed', bottom: 24, right: 24 }} onClick={() => {navigator.share({title: "Condividi "+corso[0].title.rendered,text: corso[0].title.rendered,url: "https://oratoriofmabari.it/"+corso[0].campi.tipologia.value+"/"+corso[0].slug+"/"})}}>
              <ShareRoundedIcon />
            </Fab>
          ) : (
            <SpeedDial
              ariaLabel="Condividi"
              sx={{ position: 'fixed', bottom: 24, right: 24 }}
              icon={<ShareRoundedIcon />}
            >
                <SpeedDialAction
                  key="telegram"
                  icon={<i class="fa-brands fa-telegram"></i>}
                  tooltipTitle="Telegram"
                  tooltipOpen
                  onClick={() => {window.location.href="https://t.me/share/url?url=https://oratoriofmabari.it/"+corso[0].campi.tipologia.value+"/"+corso[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="threads"
                  icon={<i class="fa-brands fa-threads"></i>}
                  tooltipTitle="Threads"
                  tooltipOpen
                  onClick={() => {window.location.href="https://threads.net/intent/post?text=https://oratoriofmabari.it/"+corso[0].campi.tipologia.value+"/"+corso[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="x"
                  icon={<i class="fa-brands fa-x-twitter"></i>}
                  tooltipTitle="X"
                  tooltipOpen
                  onClick={() => {window.location.href="https://x.com/intent/tweet?text=https://oratoriofmabari.it/"+corso[0].campi.tipologia.value+"/"+corso[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="whatsapp"
                  icon={<i class="fa-brands fa-whatsapp"></i>}
                  tooltipTitle="Whatsapp"
                  tooltipOpen
                  onClick={() => {window.location.href=" https://wa.me/?text=https://oratoriofmabari.it/"+corso[0].campi.tipologia.value+"/"+corso[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="facebook"
                  icon={<i class="fa-brands fa-facebook"></i>}
                  tooltipTitle="Facebook"
                  tooltipOpen
                  onClick={() => {window.location.href="https://www.facebook.com/sharer/sharer.php?u=https://oratoriofmabari.it/"+corso[0].campi.tipologia.value+"/"+corso[0].slug+"/";}}
                />
            </SpeedDial>
          )}
        </>
      )}
    </div>
  );
};

export default Corso;
