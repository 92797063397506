import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from 'moment';
import 'moment/locale/it';

const CardSanto = ({ santo }) =>  {
    moment.locale('it');
    return (
        <Link to={`/santo/${santo.default}`} className="oratorio--block oratorio--grid oratorio--grid-cols-3 lg:oratorio--block lg:[grid:none] *oratorio--grid-rows-1 *lg:oratorio--grid-cols-1 *lg:oratorio--grid-rows-2 oratorio--gap-2.5 oratorio--group oratorio--relative oratorio--transition oratorio--ease-out active:oratorio--opacity-75 oratorio--overflow-hidden">
           <LazyLoadImage
                    className="oratorio--shadow-lg oratorio--block oratorio--transform oratorio--transition oratorio--ease-out *group-hover:oratorio--scale-110 oratorio--object-cover oratorio--rounded-lg"
                    src={santo.urlimmagine}
                    alt={santo.nome}
                />
                <div className="oratorio--col-span-2 *lg:oratorio--row-span-1 *lg:oratorio--col-span-1 oratorio--flex oratorio--flex-col oratorio--justify-center lg:oratorio--flex-none lg:oratorio--justify-normal lg:oratorio--h-max">
                    <h1 className="oratorio--text-2xl oratorio--font-serif oratorio--mb-2 oratorio--text-left *oratorio--uppercase oratorio--font-bold" dangerouslySetInnerHTML={{ __html: santo.nome}}></h1>
                    <p className="*oratorio--mb-4 oratorio--uppercase oratorio--text-left oratorio--text-sm">Si festeggia il <strong>{moment(santo.data).format('DD MMMM')}</strong></p>
                </div>
        </Link>
    );
};
export default CardSanto;
