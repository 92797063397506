import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';
import Helmet from 'react-helmet';
import HeroHomePage from '../components/homepage/hero';
import DivisoreHomePage from '../components/homepage/divisore';
import AttivitaHomePage from '../components/homepage/attivita';
import NovitaHomePage from '../components/homepage/novita';
import CalendarioHomePage from '../components/homepage/calendario';
import Countdown from 'react-countdown';

function HomePage() {
    const expirationDate = new Date('2024-02-22T14:00:00');
    const currentDate = new Date();
    const showAlert = currentDate < expirationDate;
    return (
      <><Helmet>
        {generateHeadTags({
          title: 'Oratorio FMA San Girolamo Bari',
          description: 'Le Figlie di Maria Ausiliatrice, nel solco dello spirito salesiano e seguendo l&#039;esempio luminoso di Don Bosco e di Madre Maria Mazzarello, sono attive nella comunità del quartiere periferico di San Girolamo a Bari. La nostra missione è centrata sulla formazione dei bambini e il recupero dei giovani, con l&#039;obiettivo di farne &quot;Buoni cristiani e onesti cittadini&quot;, seguendo l&#039;ispirazione di Don Bosco.',
          keywords: 'Oratorio San Girolamo Bari, Figlie di Maria Ausiliatrice, Salesiane Don Bosco Maria Ausiliatrice, Attività Oratorio Bari, Comunità Salesiana Bari, Educazione Salesiana, Eventi Oratorio San Girolamo, Iniziative Educativo-Ricreative, Formazione Spirituale Bari, Servizio Giovanile Don Bosco, Impegno Sociale e Solidarietà, Oratorio e Cultura Salesiana, Crescita Personale e Spirituale, Incontri Giovanili Bari, Oratorio per Bambini e Ragazzi, Valori Salesiani Bari',
          openGraph: {
            type: 'website',
            title: 'Oratorio FMA San Girolamo Bari',
            image: 'https://ik.imagekit.io/oratoriofmabari/foto-attivita/666f746f686561646572.jpg',
          },
        })}
      </Helmet>
      {showAlert && (
      <section className="oratorio--py-4 md:oratorio--py-5 oratorio--bg-red-700 oratorio--text-red-100" data-aos="fade-down">
        <div className="oratorio--container oratorio--text-lg">
        <h3 className="oratorio--font-semibold oratorio--font-serif oratorio--text-2xl">Online il nuovo bando servizio civile!</h3>
        <p className="oratorio--font-medium oratorio--text-lg">I giovani interessati, tra i 18 e i 29 anni (non compiuti), possono effettuare la propria candidatura entro e non oltre le ore 14.00 del 22 febbraio 2024.</p>
        <p className="oratorio--font-medium oratorio--text-lg">Il progetto dell’Oratorio FMA San Girolamo di Bari si chiama “Tutti in gioco” con codice PTCSU0015023011883NMTX.</p>
        <Countdown
          date={expirationDate}
          intervalDelay={0}
          precision={3}
          renderer={({ days, hours, minutes, seconds, completed }) => <p className="oratorio--font-bold">Hai ancora {days!=0 && (<span>{days} giorni, </span>)}{hours!=0 && (<span>{hours} ore, </span>)}{minutes!=0 && (<span>{minutes} minuti e </span>)}{minutes!=0&&seconds!=0 && (<span>{seconds} secondi.</span>)}</p>}
        />
        <Link to={`/attivita/servizio-civile/`} className="oratorio--inline-flex oratorio--justify-center oratorio--items-center oratorio--space-x-2 oratorio--rounded oratorio--transition-all oratorio--border oratorio--font-semibold focus:oratorio--outline-none oratorio--px-2 oratorio--py-1 oratorio--leading-5 oratorio--text-sm oratorio--border-red-700 oratorio--bg-red-900 oratorio--text-white hover:oratorio--text-white hover:oratorio--bg-red-950 hover:oratorio--border-red-950 focus:oratorio--ring focus:oratorio--ring-red-500 focus:oratorio--ring-opacity-50 active:oratorio--bg-red-950 active:oratorio--border-red-950">TUTTI I DETTAGLI</Link>
        </div>
      </section>
      )}
      <HeroHomePage />
      <section className="oratorio--bg-yellow-700 oratorio--pt-1.5 oratorio--pb-3.5 -oratorio--mt-2" data-aos="fade-up" data-aos-offset="0">
        <div className="oratorio--container oratorio--text-center oratorio--text-white">
          <h2 className="oratorio--text-2xl lg:oratorio--text-3xl oratorio--font-serif oratorio--font-bold oratorio--mb-2">Ti diamo il benvenuto nella nostra casa</h2>
          <h2 className="oratorio--text-xl lg:oratorio--text-2xl oratorio--font-serif oratorio--font-medium oratorio--mb-2">Le Figlie di Maria Ausiliatrice, nel solco dello spirito salesiano e seguendo l'esempio luminoso di Don Bosco e di Madre Maria Mazzarello, sono attive nella comunità del quartiere periferico di San Girolamo a Bari. La nostra missione è centrata sulla formazione dei bambini e il recupero dei giovani, con l'obiettivo di farne "Buoni cristiani e onesti cittadini", seguendo l'ispirazione di Don Bosco.</h2>
        </div>
		  </section>
      <DivisoreHomePage />
      <AttivitaHomePage />
      <DivisoreHomePage />
      <NovitaHomePage />
      <CalendarioHomePage />
      </>
    );
  }

  export default HomePage;
