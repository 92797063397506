import React, { useState, useEffect } from 'react';
import { Skeleton, SpeedDial, SpeedDialIcon, SpeedDialAction, Fab } from '@mui/material';
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import { API_URL, DEFAULT_TITLE_SUFIX } from '../../config';
import moment from 'moment';
import 'moment/locale/it';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Fancybox from '../../components/foto/Fancybox';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';

Helmet.defaultProps.encodeSpecialCharacters = true;

const Galleria = () => {
  const [galleria, setGalleria] = useState(null);
  const [loading, setLoading] = useState(true); // Aggiunto stato di caricamento
  const { slug } = useParams();

  moment.locale('it');

  useEffect(() => {
    setLoading(true); // Impostato loading a true prima del fetch

    fetch(API_URL + 'oratoriosangirolamo/v1/foto?slug=' + slug + '&_embed')
      .then(response => response.json())
      .then(data => {
        setGalleria(data);
        setLoading(false); // Impostato loading a false dopo il completamento del fetch
      });
  }, [slug]);
  const loadingCards = Array.from({ length: 20 }, (_, index) => index + 1);
  return (
    <div>
      {loading ? (
        <>
        <div className="oratorio--block">
            <Skeleton className="oratorio--shadow-lg oratorio--mb-4 hidden-lg-up" variant="rectangular" width="100%" height={512} />
        </div>
        <main className="oratorio--pt-3 oratorio--pb-2.5">
          <div className="oratorio--w-full oratorio--mb-3">
            <div className="oratorio--container oratorio--mx-auto *oratorio--text-center +lg:oratorio--text-left">
              <h6 className="oratorio-text-base lg:oratorio--text-lg oratorio--uppercase oratorio--font-bold oratorio--mb-0.5">FOTO</h6>
              <h1 className="oratorio--text-3xl md:oratorio--text-4xl lg:oratorio--text-5xl oratorio--font-serif oratorio--font-bold oratorio--mb-2"><Skeleton className="oratorio--shadow-lg" variant="rectangular" width="100%" /></h1>
            </div>
          </div>
          <section className="oratorio--container">
            <div className="md:oratorio--columns-3xs oratorio--gap-2.5">
              {loadingCards.map((cardId) => (
                <Skeleton className="oratorio--shadow-lg" key={cardId} variant="rectangular" width="100%" height={120} />
              ))}
            </div>
          </section>
        </main>
          </>
      ) : (
        <>
          <Helmet>
            {generateHeadTags({
            title: galleria[0].title.rendered+DEFAULT_TITLE_SUFIX,
            description: 'Le Figlie di Maria Ausiliatrice, nel solco dello spirito salesiano e seguendo l&#039;esempio luminoso di Don Bosco e di Madre Maria Mazzarello, sono attive nella comunità del quartiere periferico di San Girolamo a Bari. La nostra missione è centrata sulla formazione dei bambini e il recupero dei giovani, con l&#039;obiettivo di farne &quot;Buoni cristiani e onesti cittadini&quot;, seguendo l&#039;ispirazione di Don Bosco.',
            keywords: 'Oratorio San Girolamo Bari, Figlie di Maria Ausiliatrice, Salesiane Don Bosco Maria Ausiliatrice, Attività Oratorio Bari, Comunità Salesiana Bari, Educazione Salesiana, Eventi Oratorio San Girolamo, Iniziative Educativo-Ricreative, Formazione Spirituale Bari, Servizio Giovanile Don Bosco, Impegno Sociale e Solidarietà, Oratorio e Cultura Salesiana, Crescita Personale e Spirituale, Incontri Giovanili Bari, Oratorio per Bambini e Ragazzi, Valori Salesiani Bari',
            openGraph: {
                type: 'article',
                title: galleria[0].title.rendered,
                image: galleria[0].campi.foto_evidenza.url,
                'article:author': galleria[0].autore,
                'article:published_time': galleria[0]["date"],
                'article:modified_time': galleria[0]["modified"],
                //'article:section': galleria[0].tematiche[0].name,
                /*'article:tag': galleria[0].argomenti.map((item, index) => (
                    item.name
                )),*/
                //'article:section_url': 'https://oratoriofmabari.it/tematica/'+galleria[0].tematiche[0].slug,
                /*'article:tag_url': galleria[0].argomenti.map((item, index) => (
                    'https://oratoriofmabari.it/argomento/'+item.slug
                )),*/
                'article:author_url': 'https://oratoriofmabari.it/autore/'+galleria[0].autore,
                'article:publisher': 'Oratorio FMA San Girolamo Bari',
                'article:published_first': galleria[0]["date"],
                'article:published_last': galleria[0]["date"],
                'article:modified_first': galleria[0]["modified"],
                'article:modified_last': galleria[0]["modified"],
            },
            })}
          </Helmet>
          <picture>
            <source media="(max-width: 767px)" srcSet={galleria[0].campi.sfondo.mobile.url} />
            <source media="(min-width: 768px)" srcSet={galleria[0].campi.sfondo.desktop.url} />
            <img loading="lazy" src={galleria[0].campi.sfondo.desktop.url} alt="Immagine" />
          </picture>
          <main className="oratorio--pt-3 oratorio--pb-2.5">
            <div className="oratorio--w-full oratorio--mb-3">
              <div className="oratorio--container oratorio--mx-auto *oratorio--text-center +lg:oratorio--text-left">
                <h6 className="oratorio-text-base lg:oratorio--text-lg oratorio--uppercase oratorio--font-bold oratorio--mb-0.5">FOTO</h6>
                <h1 className="oratorio--text-3xl md:oratorio--text-4xl lg:oratorio--text-5xl oratorio--font-serif oratorio--font-bold oratorio--mb-2" dangerouslySetInnerHTML={{ __html: galleria[0].title.rendered }}></h1>
              </div>
            </div>
            <section className="oratorio--container">
              <div className="md:oratorio--columns-3xs oratorio--gap-2.5">
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: false,
                    },
                  }}
                >
                {galleria[0].campi.galleria_locale.map((item, index) => (
                  <div className="oratorio--grid-item oratorio--mb-2.5" key={index}>
                    <a data-fancybox="gallery" href={item.url} data-src={item.url} data-slug={item.filename}>
                      <LazyLoadImage
                        className="oratorio--rounded oratorio--shadow-lg"
                        src={item.url}
                        alt={item.filename}
                      />
                    </a>
                    </div>
                ))}
                </Fancybox>
              </div>
            </section>
          </main>
          {navigator?.share ? (
            <Fab color="primary" aria-label="Condividi" sx={{ position: 'fixed', bottom: 24, right: 24 }} onClick={() => {navigator.share({title: "Condividi "+galleria[0].title.rendered,text: galleria[0].title.rendered,url: "https://oratoriofmabari.it/foto/"+galleria[0].slug+"/"})}}>
              <ShareRoundedIcon />
            </Fab>
          ) : (
            <SpeedDial
              ariaLabel="Condividi"
              sx={{ position: 'fixed', bottom: 24, right: 24 }}
              icon={<ShareRoundedIcon />}
            >
                <SpeedDialAction
                  key="telegram"
                  icon={<i class="fa-brands fa-telegram"></i>}
                  tooltipTitle="Telegram"
                  tooltipOpen
                  onClick={() => {window.location.href="https://t.me/share/url?url=https://oratoriofmabari.it/foto/"+galleria[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="threads"
                  icon={<i class="fa-brands fa-threads"></i>}
                  tooltipTitle="Threads"
                  tooltipOpen
                  onClick={() => {window.location.href="https://threads.net/intent/post?text=https://oratoriofmabari.it/foto/"+galleria[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="x"
                  icon={<i class="fa-brands fa-x-twitter"></i>}
                  tooltipTitle="X"
                  tooltipOpen
                  onClick={() => {window.location.href="https://x.com/intent/tweet?text=https://oratoriofmabari.it/foto/"+galleria[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="whatsapp"
                  icon={<i class="fa-brands fa-whatsapp"></i>}
                  tooltipTitle="Whatsapp"
                  tooltipOpen
                  onClick={() => {window.location.href=" https://wa.me/?text=https://oratoriofmabari.it/foto/"+galleria[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="facebook"
                  icon={<i class="fa-brands fa-facebook"></i>}
                  tooltipTitle="Facebook"
                  tooltipOpen
                  onClick={() => {window.location.href="https://www.facebook.com/sharer/sharer.php?u=https://oratoriofmabari.it/foto/"+galleria[0].slug+"/";}}
                />
            </SpeedDial>
          )}
        </>
      )}
    </div>
  );
};

export default Galleria;
