import React, { useState, useEffect, useRef } from 'react';
import { Skeleton, SpeedDial, SpeedDialIcon, SpeedDialAction, Fab } from '@mui/material';
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import { API_URL, DEFAULT_TITLE_SUFIX } from '../../config';
import moment from 'moment';
import 'moment/locale/it';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';

const Notizia = () => {
  const containerRef = useRef(null);
  const [notizia, setNotizia] = useState(null);
  const [loading, setLoading] = useState(true); // Aggiunto stato di caricamento
  const { slug } = useParams();

  moment.locale('it');

  useEffect(() => {
    setLoading(true); // Impostato loading a true prima del fetch

    fetch(API_URL + 'oratoriosangirolamo/v1/notizie?slug=' + slug + '&_embed')
      .then(response => response.json())
      .then(data => {
        setNotizia(data);
        setLoading(false);
        const container = containerRef.current;
        NativeFancybox.bind(container, ".wp-block-image img", {});
      });
  }, [slug]);

  useEffect(() => {
    return () => {
      const container = containerRef.current;
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  }, []);

  return (
    <div>
      {loading ? (
        <>
        <div className="oratorio--block lg:oratorio--hidden">
            <Skeleton className="oratorio--shadow-lg oratorio--mb-4 hidden-lg-up" variant="rectangular" width="100%" height={512} />
        </div>
        <div className="oratorio--container">
            <div className="oratorio--prose oratorio--prose-blue oratorio--max-w-none md:oratorio--max-w-4xl md:oratorio--prose-xl *oratorio--text-gray-800 oratorio--mx-auto prose-headings:oratorio--font-serif prose-headings:oratorio--font-bold *prose-headings:oratorio--uppercase prose-headings:oratorio--mb-1">
              <div className="oratorio--not-prose">
                <div className="oratorio--hidden lg:oratorio--block">
                    <Skeleton className="oratorio--shadow-lg oratorio--rounded-lg oratorio--mb-4" variant="rectangular" width="100%" height={500} />
                </div>
                <div className="oratorio--space-y-2 oratorio--uppercase oratorio--font-bold oratorio--text-sm lg:text-base">
                    <Skeleton width="60%" />
                </div>
                <h1
                  className="oratorio--text-4xl lg:oratorio--text-6xl oratorio--font-serif oratorio--mb-2 oratorio--text-left *oratorio--uppercase oratorio--font-bold oratorio--text-black"
                  ><Skeleton width="100%" /></h1>
                <p className="*oratorio--mb-4 oratorio--text-left oratorio--text-sm lg:oratorio--text-lg oratorio--text-black">
                <Skeleton width="60%" />
                </p>
              </div>
              <div>
                <Skeleton variant="rectangular" width="100%" height={512} />
              </div>
            </div>
          </div>
          </>
      ) : (
        <>
          <Helmet>
            {generateHeadTags({
            title: notizia[0].title.rendered+DEFAULT_TITLE_SUFIX,
            description: 'Le Figlie di Maria Ausiliatrice, nel solco dello spirito salesiano e seguendo l&#039;esempio luminoso di Don Bosco e di Madre Maria Mazzarello, sono attive nella comunità del quartiere periferico di San Girolamo a Bari. La nostra missione è centrata sulla formazione dei bambini e il recupero dei giovani, con l&#039;obiettivo di farne &quot;Buoni cristiani e onesti cittadini&quot;, seguendo l&#039;ispirazione di Don Bosco.',
            keywords: 'Oratorio San Girolamo Bari, Figlie di Maria Ausiliatrice, Salesiane Don Bosco Maria Ausiliatrice, Attività Oratorio Bari, Comunità Salesiana Bari, Educazione Salesiana, Eventi Oratorio San Girolamo, Iniziative Educativo-Ricreative, Formazione Spirituale Bari, Servizio Giovanile Don Bosco, Impegno Sociale e Solidarietà, Oratorio e Cultura Salesiana, Crescita Personale e Spirituale, Incontri Giovanili Bari, Oratorio per Bambini e Ragazzi, Valori Salesiani Bari',
            openGraph: {
                type: 'article',
                title: notizia[0].title.rendered,
                image: notizia[0]["_embedded"]["wp:featuredmedia"][0].source_url,
                'article:author': notizia[0].autore,
                'article:published_time': notizia[0]["date"],
                'article:modified_time': notizia[0]["modified"],
                'article:section': notizia[0].tematiche[0].name,
                'article:tag': notizia[0].argomenti.map((item, index) => (
                    item.name
                )),
                'article:section_url': 'https://oratoriofmabari.it/tematica/'+notizia[0].tematiche[0].slug,
                'article:tag_url': notizia[0].argomenti.map((item, index) => (
                    'https://oratoriofmabari.it/argomento/'+item.slug
                )),
                'article:author_url': 'https://oratoriofmabari.it/autore/'+notizia[0].autore,
                'article:publisher': 'Oratorio FMA San Girolamo Bari',
                'article:published_first': notizia[0]["date"],
                'article:published_last': notizia[0]["date"],
                'article:modified_first': notizia[0]["modified"],
                'article:modified_last': notizia[0]["modified"],
            },
            })}
          </Helmet>
          <LazyLoadImage
            className="oratorio--shadow-lg oratorio--mb-4 oratorio--block lg:oratorio--hidden"
            src={notizia[0]["_embedded"]["wp:featuredmedia"][0].source_url}
            alt={notizia[0].title.rendered}
          />
          <div className="oratorio--container">
            <div className="oratorio--prose oratorio--prose-yellow-700 oratorio--prose-a:text-yellow-700 *oratorio--max-w-none md:oratorio--prose-xl *oratorio--text-gray-800 oratorio--mx-auto prose-headings:oratorio--font-serif prose-headings:oratorio--font-bold *prose-headings:oratorio--uppercase prose-headings:oratorio--mb-1">
              <div className="oratorio--not-prose">
                <LazyLoadImage
                  className="oratorio--shadow-lg oratorio--rounded-lg oratorio--mb-4 oratorio--hidden lg:oratorio--block"
                  src={notizia[0]["_embedded"]["wp:featuredmedia"][0].source_url}
                  alt={notizia[0].title.rendered}
                />
                <div className="oratorio--space-y-2 oratorio--uppercase oratorio--font-bold oratorio--text-sm lg:text-base">
                  {notizia[0].tematiche.map((item, index) => (
                    <span key={index}>{item.name}</span>
                  ))}
                </div>
                <h1
                  className="oratorio--text-4xl lg:oratorio--text-6xl oratorio--font-serif oratorio--mb-2 oratorio--text-left *oratorio--uppercase oratorio--font-bold oratorio--text-black"
                  dangerouslySetInnerHTML={{ __html: notizia[0].title.rendered }}
                ></h1>
                <p className="*oratorio--mb-4 oratorio--text-left oratorio--text-sm lg:oratorio--text-lg oratorio--text-black">
                  <span className="oratorio--uppercase">
                    <strong>{moment(notizia[0]["date"]).format('DD MMMM YYYY')}</strong>{' '}
                    {moment(notizia[0]["date"]).format('HH:ss')}
                  </span>
                  &nbsp;&nbsp;
                  <i className="fa-light fa-user"></i> {notizia[0].autore}
                </p>
              </div>
              <div ref={containerRef} dangerouslySetInnerHTML={{ __html: notizia[0].content.rendered }}></div>
            </div>
          </div>
          {navigator?.share ? (
            <Fab color="primary" aria-label="Condividi" sx={{ position: 'fixed', bottom: 24, right: 24 }} onClick={() => {navigator.share({title: "Condividi "+notizia[0].title.rendered,text: notizia[0].title.rendered,url: "https://oratoriofmabari.it/notizia/"+notizia[0].slug+"/"})}}>
              <ShareRoundedIcon />
            </Fab>
          ) : (
            <SpeedDial
              ariaLabel="Condividi"
              sx={{ position: 'fixed', bottom: 24, right: 24 }}
              icon={<ShareRoundedIcon />}
            >
                <SpeedDialAction
                  key="telegram"
                  icon={<i class="fa-brands fa-telegram"></i>}
                  tooltipTitle="Telegram"
                  tooltipOpen
                  onClick={() => {window.location.href="https://t.me/share/url?url=https://oratoriofmabari.it/notizia/"+notizia[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="threads"
                  icon={<i class="fa-brands fa-threads"></i>}
                  tooltipTitle="Threads"
                  tooltipOpen
                  onClick={() => {window.location.href="https://threads.net/intent/post?text=https://oratoriofmabari.it/notizia/"+notizia[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="x"
                  icon={<i class="fa-brands fa-x-twitter"></i>}
                  tooltipTitle="X"
                  tooltipOpen
                  onClick={() => {window.location.href="https://x.com/intent/tweet?text=https://oratoriofmabari.it/notizia/"+notizia[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="whatsapp"
                  icon={<i class="fa-brands fa-whatsapp"></i>}
                  tooltipTitle="Whatsapp"
                  tooltipOpen
                  onClick={() => {window.location.href=" https://wa.me/?text=https://oratoriofmabari.it/notizia/"+notizia[0].slug+"/";}}
                />
                <SpeedDialAction
                  key="facebook"
                  icon={<i class="fa-brands fa-facebook"></i>}
                  tooltipTitle="Facebook"
                  tooltipOpen
                  onClick={() => {window.location.href="https://www.facebook.com/sharer/sharer.php?u=https://oratoriofmabari.it/notizia/"+notizia[0].slug+"/";}}
                />
            </SpeedDial>
          )}
        </>
      )}
    </div>
  );
};

export default Notizia;

