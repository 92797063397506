import React, { useEffect, useState } from 'react';
import { API_URL } from '../../config';
import { CardHome as CardNotizieHome } from '../notizie/cardhome';
import { CardBigHome as CardNotizieHomeBig } from '../notizie/cardbighome';
import { CardHome as CardFotoHome } from '../foto/cardhome';
import { CardBigHome as CardFotoHomeBig } from '../foto/cardbighome';

const NovitaHomePage = () => {
    const [notizie, setNotizie] = useState([]);
    const [foto, setFoto] = useState([]);

	useEffect(() => {
		fetch(API_URL+'oratoriosangirolamo/v1/notizie?per_page=3&order=desc&orderby=date&_embed')
			.then(response => response.json())
			.then(data => setNotizie(data));
        fetch(API_URL+'oratoriosangirolamo/v1/foto?per_page=3&order=desc&orderby=date&_embed')
			.then(response => response.json())
			.then(data => setFoto(data));
	}, []);
    return (
        <section className="oratorio--container oratorio--py-2" data-aos="fade-up">
			<h2 className="oratorio--text-2xl lg:oratorio--text-3xl oratorio--font-serif oratorio--font-bold oratorio--mb-2">Novità</h2>
			<div className="oratorio--grid oratorio--grid-cols-1 lg:oratorio--grid-cols-2 oratorio--gap-3">
				<div className="">
					<div className="oratorio--bg-yellow-700 oratorio--text-white oratorio--text-lg oratorio--py-2 oratorio--font-serif oratorio--font-bold oratorio--uppercase oratorio--text-center"><p>NEWS</p></div>
					<div className="oratorio--w-full oratorio--overflow-hidden oratorio--leading-[0] oratorio--mb-2.5">
						<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 15" preserveAspectRatio="none">
							<rect x="1200" height="3.6"></rect>
							<rect height="3.6"></rect>
							<path d="M0,0V3.6H580.08c11,0,19.92,5.09,19.92,13.2,0-8.14,8.88-13.2,19.92-13.2H1200V0Z" className="shape-fill" style={{ fill: "#A16207" }}></path>
						</svg>
					</div>
                    <div className="oratorio--space-y-2">
                    {notizie ? (
                        <>
                        {notizie.map((item, index) => (
                            index === 0 ? (
                                <CardNotizieHomeBig key={item.id} notizia={item} />
                            ) : (
                                <CardNotizieHome key={item.id} notizia={item} />
                            )
                        ))}
                        </>
                    ) : (
                        <>
                        <div className="oratorio--animate-pulse oratorio--h-64 oratorio--bg-gray-200"></div>
                        </>
                      )}
                    </div>
					<div className="oratorio--my-2 oratorio--text-center">
						<a href="/notizie/" className="oratorio--inline-flex oratorio--justify-center oratorio--items-center oratorio--space-x-2 oratorio--rounded oratorio--border oratorio--font-semibold focus:oratorio--outline-none oratorio--px-2 oratorio--py-1 oratorio--leading-5 oratorio--text-sm oratorio--border-yellow-700 oratorio--bg-yellow-700 oratorio--text-white hover:oratorio--text-white hover:oratorio--bg-yellow-800 hover:oratorio--border-yellow-800 focus:oratorio--ring focus:oratorio--ring-yellow-500 focus:oratorio--ring-opacity-50 active:oratorio--bg-yellow-700 active:oratorio--border-yellow-700 oratorio--mx-auto oratorio--uppercase">Tutte le notizie</a>
					</div>
				</div>
				<div className="">
					<div className="oratorio--bg-yellow-700 oratorio--text-white oratorio--text-lg oratorio--py-2 oratorio--font-serif oratorio--font-bold oratorio--uppercase oratorio--text-center"><p>FOTO</p></div>
					<div className="oratorio--w-full oratorio--overflow-hidden oratorio--leading-[0] oratorio--mb-2.5">
						<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 15" preserveAspectRatio="none">
							<rect x="1200" height="3.6"></rect>
							<rect height="3.6"></rect>
							<path d="M0,0V3.6H580.08c11,0,19.92,5.09,19.92,13.2,0-8.14,8.88-13.2,19.92-13.2H1200V0Z" className="shape-fill" style={{ fill: "#A16207" }}></path>
						</svg>
					</div>
					<div className="oratorio--space-y-2">
                    {foto ? (
                        <>
                        {foto.map((item, index) => (
                            index === 0 ? (
                                <CardFotoHomeBig key={item.id} foto={item} />
                            ) : (
                                <CardFotoHome key={item.id} foto={item} />
                            )
                        ))}
                        </>
                    ) : (
                        <>
                        <div className="oratorio--animate-pulse oratorio--h-64 oratorio--bg-gray-200"></div>
                        </>
                      )}
                    </div>
					<div className="oratorio--my-2 oratorio--text-center">
						<a href="/foto/" className="oratorio--inline-flex oratorio--justify-center oratorio--items-center oratorio--space-x-2 oratorio--rounded oratorio--border oratorio--font-semibold focus:oratorio--outline-none oratorio--px-2 oratorio--py-1 oratorio--leading-5 oratorio--text-sm oratorio--border-yellow-700 oratorio--bg-yellow-700 oratorio--text-white hover:oratorio--text-white hover:oratorio--bg-yellow-800 hover:oratorio--border-yellow-800 focus:oratorio--ring focus:oratorio--ring-yellow-500 focus:oratorio--ring-opacity-50 active:oratorio--bg-yellow-700 active:oratorio--border-yellow-700 oratorio--uppercase">Tutte le foto</a>
					</div>
				</div>
			</div>
		</section>
    );
};

export default NovitaHomePage;
