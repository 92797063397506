import React, { useEffect, useState } from 'react';
import { API_URL } from '../../config';
import CardAttivita from '../attivita/card';

const AttivitaHomePage = () => {
    const [attivita, setAttivita] = useState([]);

	useEffect(() => {
		fetch(API_URL+'/oratoriosangirolamo/v1/attivita?per_page=6&order=asc&orderby=title')
			.then(response => response.json())
			.then(data => setAttivita(data));
	}, []);
    return (
        <section className="oratorio--container oratorio--py-2" data-aos="fade-up">
		    <h2 className="oratorio--text-2xl lg:oratorio--text-3xl oratorio--font-serif oratorio--font-bold oratorio--mb-2">Le nostre attività</h2>
			<div className="[&_a]:oratorio--w-3/4 md:[&_a]:oratorio--w-full oratorio--snap-x md:oratorio--snap-none oratorio--snap-mandatory oratorio--flex md:oratorio--grid md:oratorio--grid-cols-2 lg:oratorio--grid-cols-3 *2xl:oratorio--grid-cols-4 oratorio--overflow-x-auto">
			{attivita.map(item => (
                <CardAttivita key={item.id} attivita={item} />
            ))}
			</div>
		</section>
    );
};

export default AttivitaHomePage;
