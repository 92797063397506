import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from 'moment';
import 'moment/locale/it';

export const CardHome = ({ notizia }) =>  {
    moment.locale('it');
    return (
        <Link to={`/notizia/${notizia.slug}`} className="oratorio--block oratorio--grid oratorio--grid-cols-3 oratorio--gap-2.5 oratorio--group oratorio--relative oratorio--transition oratorio--ease-out active:oratorio--opacity-75 oratorio--overflow-hidden">
           <LazyLoadImage
                    className="oratorio--shadow-lg oratorio--block oratorio--transform oratorio--transition oratorio--ease-out *group-hover:oratorio--scale-110 oratorio--object-cover oratorio--rounded-lg"
                    src={notizia["_embedded"]["wp:featuredmedia"][0].source_url}
                    alt={notizia.title.rendered}
                />
                <div className="oratorio--col-span-2 oratorio--flex oratorio--flex-col oratorio--justify-center">
                    <div className="oratorio--space-y-2 oratorio--uppercase oratorio--font-bold oratorio--text-sm lg:text-base">
                        {notizia.tematiche.map((item, index) => (
                            <span key={index}>{item.name}</span>
                        ))}
                    </div>
                    <h1 className="oratorio--text-2xl oratorio--font-serif oratorio--mb-2 oratorio--text-left *oratorio--uppercase oratorio--font-bold" dangerouslySetInnerHTML={{ __html: notizia.title.rendered}}></h1>
                    <p className="*oratorio--mb-4 oratorio--uppercase oratorio--text-left oratorio--text-sm"><strong>{moment(notizia["date"]).format('DD MMMM YYYY')}</strong> {moment(notizia["date"]).format('HH:ss')}</p>
                </div>
        </Link>
    );
};
