import React from 'react';
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

function Pagina404() {
    return (
      <><Helmet>
        {generateHeadTags({
          title: 'Oratorio FMA San Girolamo Bari',
          description: 'Le Figlie di Maria Ausiliatrice, nel solco dello spirito salesiano e seguendo l&#039;esempio luminoso di Don Bosco e di Madre Maria Mazzarello, sono attive nella comunità del quartiere periferico di San Girolamo a Bari. La nostra missione è centrata sulla formazione dei bambini e il recupero dei giovani, con l&#039;obiettivo di farne &quot;Buoni cristiani e onesti cittadini&quot;, seguendo l&#039;ispirazione di Don Bosco.',
          keywords: 'Oratorio San Girolamo Bari, Figlie di Maria Ausiliatrice, Salesiane Don Bosco Maria Ausiliatrice, Attività Oratorio Bari, Comunità Salesiana Bari, Educazione Salesiana, Eventi Oratorio San Girolamo, Iniziative Educativo-Ricreative, Formazione Spirituale Bari, Servizio Giovanile Don Bosco, Impegno Sociale e Solidarietà, Oratorio e Cultura Salesiana, Crescita Personale e Spirituale, Incontri Giovanili Bari, Oratorio per Bambini e Ragazzi, Valori Salesiani Bari',
          openGraph: {
            type: 'website',
            title: 'Oratorio FMA San Girolamo Bari',
            image: 'https://ik.imagekit.io/oratoriofmabari/foto-attivita/666f746f686561646572.jpg',
          },
        })}
      </Helmet>
      <main className="oratorio--container oratorio--text-center oratorio--min-h-[75vh] oratorio--flex oratorio--items-center oratorio--justify-center">
            <div className="oratorio--text-center">
                <p className="oratorio--text-9xl oratorio--font-serif oratorio--mb-2.5 oratorio--font-bold">404</p>
                <p className="oratorio--text-4xl oratorio--font-serif oratorio--mb-5">Pagina non trovata</p>
                <Link to="/" className="oratorio--text-lg oratorio--flex-none oratorio--font-medium *oratorio--text-blue-700 *hover:oratorio--text-blue-900 oratorio--transition-all oratorio--uppercase">Torna alla home</Link>
            </div>
        </main>
      </>
    );
  }

  export default Pagina404;
