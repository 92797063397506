import React from 'react';
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';
import Helmet from 'react-helmet';
import { DEFAULT_TITLE_SUFIX, API_URL } from '../config';
import { useState, useEffect } from 'react';
import CardAttivita from '../components/attivita/card';
import Skeleton from '@mui/material/Skeleton';

function Attivita() {
    const [attivitae, setAttivita] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      fetch(API_URL+'oratoriosangirolamo/v1/attivita?per_page=100&order=asc&orderby=title')
        .then(response => response.json())
        .then(data => setAttivita(data));
      setIsLoading(false);
    }, []);
    return (
      <>
      <Helmet>
        {generateHeadTags({
          title: 'Attività'+DEFAULT_TITLE_SUFIX,
          description: 'Le Figlie di Maria Ausiliatrice, nel solco dello spirito salesiano e seguendo l&#039;esempio luminoso di Don Bosco e di Madre Maria Mazzarello, sono attive nella comunità del quartiere periferico di San Girolamo a Bari. La nostra missione è centrata sulla formazione dei bambini e il recupero dei giovani, con l&#039;obiettivo di farne &quot;Buoni cristiani e onesti cittadini&quot;, seguendo l&#039;ispirazione di Don Bosco.',
          keywords: 'Oratorio San Girolamo Bari, Figlie di Maria Ausiliatrice, Salesiane Don Bosco Maria Ausiliatrice, Attività Oratorio Bari, Comunità Salesiana Bari, Educazione Salesiana, Eventi Oratorio San Girolamo, Iniziative Educativo-Ricreative, Formazione Spirituale Bari, Servizio Giovanile Don Bosco, Impegno Sociale e Solidarietà, Oratorio e Cultura Salesiana, Crescita Personale e Spirituale, Incontri Giovanili Bari, Oratorio per Bambini e Ragazzi, Valori Salesiani Bari',
          openGraph: {
            type: 'website',
            title: 'Attività',
            image: 'https://ik.imagekit.io/oratoriofmabari/foto-pagine/6f7261746f72696f2d61747469766974612d6d6f62696c65.png',
          },
        })}
      </Helmet>
      <picture>
        <source media="(max-width: 767px)" srcSet="https://ik.imagekit.io/oratoriofmabari/foto-pagine/6f7261746f72696f2d61747469766974612d6d6f62696c65.png" />
        <source media="(min-width: 768px)" srcSet="https://ik.imagekit.io/oratoriofmabari/foto-pagine/6f7261746f72696f2d61747469766974612d6465736b746f70.png" />
        <img loading="lazy" src="https://ik.imagekit.io/oratoriofmabari/foto-pagine/6f7261746f72696f2d61747469766974612d6465736b746f70.png" alt="Immagine" />
      </picture>
      <main className="oratorio--pt-3 oratorio--pb-2.5">
		<div className="oratorio--w-full oratorio--mb-3">
			<div className="oratorio--container oratorio--mx-auto *oratorio--text-center +lg:oratorio--text-left">
				<h1 className="oratorio--text-3xl md:oratorio--text-4xl lg:oratorio--text-5xl oratorio--font-serif oratorio--font-bold oratorio--mb-2">Attività</h1>
			</div>
		</div>
		<section className="oratorio--container">
        <div>
      {isLoading ? (
        // Placeholder durante il caricamento
        <p>Caricamento...</p>
      ) : attivitae.length > 0 ? (
        <div className="oratorio--snap-none oratorio--grid oratorio-grid-cols-1 md:oratorio--grid-cols-2 lg:oratorio--grid-cols-3 2xl:oratorio--grid-cols-4">
          {attivitae.map((attivita) => (
            <div key={attivita.id}><CardAttivita attivita={attivita}></CardAttivita></div>
          ))}
        </div>
      ) : (
        <><Skeleton variant="rectangular" width="100%" height={118} /><div className="oratorio--hidden oratorio--container oratorio--text-center oratorio--min-h-[75vh] oratorio--flex oratorio--items-center oratorio--justify-center">
                      <div className="oratorio--text-center">
                        <p className="oratorio--text-9xl oratorio--font-serif oratorio--mb-2.5 oratorio--font-bold">OPS</p>
                        <p className="oratorio--text-4xl oratorio--font-serif oratorio--mb-5">Non ho trovato attività, ritorna più tardi</p>
                        <a href="/" className="oratorio--text-lg oratorio--flex-none oratorio--font-medium *oratorio--text-blue-700 *hover:oratorio--text-blue-900 oratorio--transition-all oratorio--uppercase">Torna alla home</a>
                      </div>
                    </div></>
      )}
    </div>
		</section>
	</main>
  </>
    );
  }

  export default Attivita;