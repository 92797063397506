import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import { API_URL, DEFAULT_TITLE_SUFIX } from '../config';
import moment from 'moment';
import 'moment/locale/it';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Pagina404 from './404';

const Page = () => {
  const [pagina, setPagina] = useState(null);
  const [loading, setLoading] = useState(true); // Aggiunto stato di caricamento
  const { slug } = useParams();

  moment.locale('it');

  useEffect(() => {
    setLoading(true); // Impostato loading a true prima del fetch

    fetch(API_URL + 'wp/v2/pages?slug=' + slug + '&_embed')
      .then(response => response.json())
      .then(data => {
        setPagina(data);
        setLoading(false);
      });
  }, [slug]);

  return (
    <div>
      {loading ? (
        <>
        <p></p>
        </>
      ) : (
        <>
        {pagina && pagina.length > 0 ? (
        <>
          <Helmet>
            {generateHeadTags({
            title: pagina[0].title.rendered+DEFAULT_TITLE_SUFIX,
            description: 'Le Figlie di Maria Ausiliatrice, nel solco dello spirito salesiano e seguendo l&#039;esempio luminoso di Don Bosco e di Madre Maria Mazzarello, sono attive nella comunità del quartiere periferico di San Girolamo a Bari. La nostra missione è centrata sulla formazione dei bambini e il recupero dei giovani, con l&#039;obiettivo di farne &quot;Buoni cristiani e onesti cittadini&quot;, seguendo l&#039;ispirazione di Don Bosco.',
            keywords: 'Oratorio San Girolamo Bari, Figlie di Maria Ausiliatrice, Salesiane Don Bosco Maria Ausiliatrice, Attività Oratorio Bari, Comunità Salesiana Bari, Educazione Salesiana, Eventi Oratorio San Girolamo, Iniziative Educativo-Ricreative, Formazione Spirituale Bari, Servizio Giovanile Don Bosco, Impegno Sociale e Solidarietà, Oratorio e Cultura Salesiana, Crescita Personale e Spirituale, Incontri Giovanili Bari, Oratorio per Bambini e Ragazzi, Valori Salesiani Bari',
            openGraph: {
                type: 'website',
                title: pagina[0].title.rendered,
                image: '',
            },
            })}
          </Helmet>
          <main className="oratorio--w-full oratorio--py-2.5">
                <section className="oratorio--container oratorio--mx-auto oratorio--mb-2">
                    <h1 className="oratorio--text-3xl md:oratorio--text-4xl lg:oratorio--text-5xl oratorio--font-serif oratorio--font-bold" dangerouslySetInnerHTML={{ __html: pagina[0].title.rendered }}></h1>
                </section>
                <section className="oratorio--container">
                    <div className="oratorio--prose oratorio--prose-blue oratorio--max-w-none md:oratorio--prose-lg *oratorio--text-gray-800 oratorio--mx-auto prose-headings:oratorio--font-serif prose-headings:oratorio--font-bold *prose-headings:oratorio--uppercase prose-headings:oratorio--mb-1" dangerouslySetInnerHTML={{ __html: pagina[0].content.rendered }}></div>
                </section>
            </main>
        </>
        ) : (
            <Pagina404 />
          )}
        </>
      )}
    </div>
  );
};

export default Page;
