import React from 'react';

const Sfondo = ({ children }) => {
  return (
    <div className="oratorio--bg-yellow-50 oratorio--transition-all oratorio--min-h-screen">
      {children}
    </div>
  );
};

export default Sfondo;