import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import { API_URL } from '../../config';
import { Snackbar, Alert } from '@mui/material';
import CardNotizia from '../notizie/card';
import CardFoto from '../foto/card';
import CardSanto from '../santo/card';

const Header = () => {
	const [menuItems, setMenuItems] = useState([]);
	const [openIscrizioni, setOpenIscrizioni] = useState(false);
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [isSearchOpen, setSearchOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [searchResults, setSearchResults] = useState([]);

	useEffect(() => {
		fetch(API_URL+'/oratoriosangirolamo/v1/menu/2')
			.then(response => response.json())
			.then(data => setMenuItems(data));
	}, []);
	const navigate = useNavigate();
	const toggleMenu = () => {
		setMenuOpen((prev) => !prev);
	};
	const toggleSearch = () => {
		setSearchOpen((prev) => !prev);
	};
	const toggleMenuLink = () => {
		setMenuOpen(false);
	}
	useEffect(() => {
		const body = document.body;
		if (isSearchOpen) {
		  body.style.overflow = 'hidden';
		} else {
		  body.style.overflow = 'visible';
		}
		return () => {
		  body.style.overflow = 'visible';
		};
	}, [isSearchOpen]);
	const fetchDataFromApi1 = async (query) => {
		const response = await fetch(API_URL+`oratoriosangirolamo/v1/cerca/?search_term=${query}&_embed`);
		const data = await response.json();
		return data;
	};

	const fetchDataFromApi2 = async (query) => {
		const response = await fetch(`https://www.santodelgiorno.it/santi.json?q=${query}`);
		const data = await response.json();
		return data;
	};
	const handleSearch = async (e) => {
		e.preventDefault();
		try {
			var apiResults = [];
			apiResults["sito"] = await fetchDataFromApi1(searchQuery);
			apiResults["santi"] = await fetchDataFromApi2(searchQuery);
			setSearchResults(apiResults);
			console.log(apiResults);
			console.log(searchResults);
		} catch (error) {
			console.error('Errore durante la ricerca:', error);
		}
	};

	return (
		<><header className="oratorio--sticky oratorio--top-0 oratorio--w-full oratorio--z-[500] oratorio--h-header oratorio--transition-all oratorio--bg-yellow-50" id="header">
			<div className="oratorio--container oratorio--flex oratorio--content-center oratorio--items-center oratorio--h-header">
				<div className="oratorio--w-full oratorio--flex oratorio--flex-row oratorio--justify-between oratorio--content-center lg:oratorio--items-center">
					<Link to={`/`} className="oratorio--inline-block oratorio--font-sans oratorio--text-2xl oratorio--font-extrabold oratorio--text-left oratorio--text-black oratorio--no-underline oratorio--bg-transparent oratorio--cursor-pointer focus:oratorio--no-underline oratorio--leading-none">
						<span>FMA BARI</span>
						<span className="oratorio--block oratorio--font-light oratorio--text-sm">San Girolamo</span>
					</Link>
					<div className="oratorio--flex oratorio--flex-row oratorio--justify-between oratorio--content-center lg:oratorio--items-center">
						<a className="lg:oratorio--hidden oratorio--text-xl oratorio--flex-none oratorio--text-black oratorio--transition-all oratorio--font-medium oratorio--inline-flex oratorio--items-center oratorio--mr-2" onClick={toggleSearch}>
							<i className={`fa-light *oratorio--ml-1 ${isSearchOpen ? 'fa-x' : 'fa-magnifying-glass'}`}></i>
						</a>
						<a className={`lg:oratorio--hidden oratorio--inline-flex oratorio--items-center oratorio--font-bold oratorio--transition-all ${isSearchOpen ? 'oratorio--hidden' : ''}`} id="iconmenu" onClick={toggleMenu}>{isMenuOpen ? 'CHIUDI' : 'MENU'}<i className={`fa-light oratorio--ml-1 ${isMenuOpen ? 'fa-x' : 'fa-grip-lines'}`}></i></a>
					</div>
					<div className={`oratorio--transition-all oratorio--bg-yellow-50 lg:oratorio--text-current oratorio--fixed oratorio--${isMenuOpen ? 'block' : 'hidden'} lg:oratorio--block lg:oratorio--static oratorio--w-full lg:oratorio--w-fit oratorio--h-fulw lg:oratorio--h-fit oratorio--top-header oratorio--left-0 oratorio--right-0 oratorio--bottom-0 oratorio--container lg:oratorio--p-0 lg:oratorio--m-0`}>
						<nav className={`oratorio--w-full oratorio--flex oratorio--flex-col lg:oratorio--flex-row lg:oratorio--gap-x-3.5 oratorio--gap-y-2.5 lg:oratorio--gap-y-0 oratorio--text-3xl lg:oratorio--text-lg lg:oratorio--items-center oratorio--uppercase`}>
							{menuItems.map(item => (
								<NavLink
									key={item.id}
									to={item.url}
									className={({ isActive }) => {
										const textClass = isActive ? "oratorio--text-2xl lg:oratorio--text-base oratorio--flex-none oratorio--text-black oratorio--transition-all oratorio--font-bold" : "oratorio--text-2xl lg:oratorio--text-base oratorio--flex-none oratorio--text-black oratorio--transition-all oratorio--font-medium";
										const hiddenClass = isSearchOpen ? "oratorio--hidden" : "";
										return `${textClass} ${hiddenClass}`;
									}}
									onClick={toggleMenuLink}
									>
									{item.etichetta}
									</NavLink>
							))}
							<a className="oratorio--hidden lg:oratorio--block oratorio--text-2xl lg:oratorio--text-lg oratorio--flex-none oratorio--text-black oratorio--transition-all oratorio--font-medium" onClick={toggleSearch}>
								<i className={`fa-light *oratorio--ml-1 ${isSearchOpen ? 'fa-x' : 'fa-magnifying-glass'}`}></i>
							</a>
							<div className="lg:oratorio--hidden oratorio--grow"></div>
							<a onClick={() => setOpenIscrizioni(true)} className={`${isSearchOpen ? 'oratorio--hidden ' : ''}oratorio--px-4 oratorio--py-2 oratorio--font-bold oratorio--text-white oratorio--bg-black *oratorio--bg-blue-700 *hover:oratorio--bg-blue-900 oratorio--rounded oratorio--transition-all oratorio--mb-5 lg:oratorio--mb-0 oratorio--text-center lg:oratorio--text-left oratorio--uppercase lg:oratorio--normal-case`}>ISCRIZIONI</a>
						</nav>
					</div>
					<div className={`oratorio--transition-all oratorio--bg-yellow-50 lg:oratorio--text-current oratorio--fixed oratorio--${isSearchOpen ? 'block' : 'hidden'} oratorio--w-full oratorio--h-fulw oratorio--top-header oratorio--left-0 oratorio--right-0 oratorio--bottom-0 oratorio--overflow-y-auto`}>
						<div className="oratorio--container">
							<div className="oratorio--flex oratorio--justify-center oratorio--items-center oratorio--h-full">
								<form className="oratorio--w-full oratorio--flex oratorio--justify-center oratorio--items-center" onSubmit={handleSearch}>
									<input type="text" className="oratorio--w-full oratorio--py-3 oratorio--px-5 oratorio--text-2xl oratorio--font-medium oratorio--text-black oratorio--border-2 oratorio--border-black oratorio--rounded oratorio--transition-all -focus:oratorio--outline-none focus:oratorio--border-2 focus:oratorio--border-black oratorio--caret-black" placeholder="Cerca..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
									<a className="oratorio--text-2xl oratorio--flex-none oratorio--text-black oratorio--transition-all oratorio--font-medium oratorio--inline-flex oratorio--items-center oratorio--px-5 *oratorio--border-2 oratorio--border-black oratorio--rounded-r" onClick={handleSearch}>
										<i className={`fa-light *oratorio--ml-1 fa-magnifying-glass`}></i>
									</a>
								</form>
							</div>
								{searchResults["sito"] && (
								<div className="oratorio--results-container oratorio--pb-2.5">
										<div>
										<div className="oratorio--grid oratorio-grid-cols-1 md:oratorio--grid-cols-2 lg:oratorio--grid-cols-3 2xl:oratorio--grid-cols-4 oratorio--gap-2.5 oratorio--mt-2.5">
        									{searchResults["sito"].map((result, index) => {
												if (result.type === "notizie") {
													return <CardNotizia azioneclick={toggleSearch} key={index} notizia={result} />;
												} else if (result.type === "foto") {
													return <CardFoto key={index} foto={result} />;
												}
												return null;
											})}
											</div>
											<div className="oratorio--grid oratorio-grid-cols-1 md:oratorio--grid-cols-2 lg:oratorio--grid-cols-3 2xl:oratorio--grid-cols-4 oratorio--gap-2.5 oratorio--mt-2.5">
         									{searchResults["santi"].map((result, index) => (
												<CardSanto key={index} santo={result} />
											))}
											</div>
										</div>
								</div>
								)}
						</div>
					</div>
				</div>
			</div>
		</header>
		<Snackbar open={openIscrizioni} autoHideDuration={6000} onClose={() => setOpenIscrizioni(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
			<Alert onClose={() => setOpenIscrizioni(false)} severity="error" sx={{ width: '100%' }}>Non ci sono iscrizioni aperte al momento</Alert>
		</Snackbar>
		</>
	);
}

export default Header;
