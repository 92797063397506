import React from 'react';
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AOS from 'aos';

const HeroHomePage = () => {
    AOS.init();
    return (
        <section className="oratorio--w-full oratorio--min-h-[calc(var(--fullheightin)-4rem)] lg:oratorio--h-fulw oratorio--flex oratorio--flex-col oratorio--place-content-between">
			<div className="oratorio--container oratorio--mx-auto">
			<div className="oratorio--flex oratorio--flex-col oratorio--flex-wrap oratorio--w-full oratorio--py-5 oratorio--mx-auto oratorio--leading-6 oratorio--text-black lg:oratorio--flex-row">
			<div className="oratorio--box-border +oratorio--relative oratorio--w-full oratorio--text-black lg:oratorio--w-6/12 oratorio--flex oratorio--items-center oratorio--place-content-between oratorio--order-2 lg:oratorio--order-1 oratorio--text-center lg:oratorio--text-left">
				<div>
					<p className="oratorio--pt-4 oratorio--mx-auto oratorio--mb-4 oratorio--text-4xl oratorio--font-black oratorio--leading-tight oratorio--text-gray-900 xl:oratorio--pt-6 sm:oratorio--text-5xl lg:oratorio--text-4xl xl:oratorio--text-5xl oratorio--font-serif" data-aos="fade-up">«Casa che accoglie, ti senti in famiglia»</p>
					<p className="oratorio--pt-3 oratorio--mb-2 lg:oratorio--mr-12 oratorio--text-xl oratorio--font-normal oratorio--leading-relaxed oratorio--text-gray-900 lg:oratorio--mb-4 xl:oratorio--pt-5 sm:oratorio--text-2xl lg:oratorio--text-xl xl:oratorio--text-2xl" data-aos="fade-up">#noicistiamo</p>
					<div className="oratorio--pt-5 oratorio--space-y-3 oratorio--space-y-4 oratorio--leading-6 xl:oratorio--pt-8 xl:oratorio--space-y-5" data-aos="zoom-in">
						<div className="oratorio--relative oratorio--inline-block oratorio--group">
							<div className="oratorio--w-full oratorio--h-full oratorio--bg-yellow-700 oratorio--ml-0.5 oratorio--mt-0.5 oratorio--absolute oratorio--left-0 oratorio--top-0 oratorio--rounded group-hover:oratorio--hidden"></div>
							<Link className="oratorio--relative oratorio--inline-block oratorio--px-5 oratorio--py-2 oratorio--text-lg oratorio--font-bold oratorio--text-center oratorio--text-yellow-700 oratorio--no-underline oratorio--whitespace-no-wrap oratorio--align-middle oratorio--bg-white oratorio--border-2 oratorio--border-yellow-700 oratorio--border-solid oratorio--shadow-xs oratorio--cursor-pointer oratorio--select-none hover:oratorio--text-white hover:oratorio--shadow-xs hover:oratorio--bg-yellow-700 focus:oratorio--shadow-xs oratorio--rounded oratorio--transition-all" to="/attivita/">Le nostre attività</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="*oratorio--relative oratorio--flex-1 oratorio--w-full oratorio--text-black lg:oratorio--w-6/12 oratorio--order-1 lg:oratorio--order-2">
			    <LazyLoadImage
                    className="oratorio--w-full oratorio--bow-shadow oratorio--h-[41vh] lg:oratorio--h-[75vh] oratorio--object-contain lg:oratorio--object-right"
                    src="https://wsrv.nl/?url=https://ik.imagekit.io/oratoriofmabari/tr:f-auto/foto-attivita/666f746f686561646572.jpg&w=1024&h=1024&fit=cover&a=center&mask=circle&il&output=webp&f=80"
                    alt="«Casa che accoglie, ti senti in famiglia»"
                    data-aos="zoom-in"
                />
			</div>
			</div>
			</div>
            <div className="oratorio--w-full oratorio--overflow-hidden oratorio--leading-[0]" data-aos="fade-up" data-aos-offset="0">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 140" preserveAspectRatio="none" style={{ width: "calc(100% + 1.3px)", height: "50px" }}>
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83" style={{ fill: "#A16207" }}></path>
                </svg>
            </div>
        </section>
    );
};

export default HeroHomePage;
