import React from 'react';
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';
import Helmet from 'react-helmet';
import { MapContainer, TileLayer, useMap, Marker } from 'react-leaflet';
import { DEFAULT_TITLE_SUFIX } from '../config';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import iconaPin from '../assets/img/pin-oratorio.png';

function HomePage() {
    const customIcon = new L.Icon({
      iconUrl: iconaPin,
      iconSize: [25, 36],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
    });
    return (
      <><Helmet>
        {generateHeadTags({
          title: 'Contatti'+DEFAULT_TITLE_SUFIX,
          description: 'Le Figlie di Maria Ausiliatrice, nel solco dello spirito salesiano e seguendo l&#039;esempio luminoso di Don Bosco e di Madre Maria Mazzarello, sono attive nella comunità del quartiere periferico di San Girolamo a Bari. La nostra missione è centrata sulla formazione dei bambini e il recupero dei giovani, con l&#039;obiettivo di farne &quot;Buoni cristiani e onesti cittadini&quot;, seguendo l&#039;ispirazione di Don Bosco.',
          keywords: 'Oratorio San Girolamo Bari, Figlie di Maria Ausiliatrice, Salesiane Don Bosco Maria Ausiliatrice, Attività Oratorio Bari, Comunità Salesiana Bari, Educazione Salesiana, Eventi Oratorio San Girolamo, Iniziative Educativo-Ricreative, Formazione Spirituale Bari, Servizio Giovanile Don Bosco, Impegno Sociale e Solidarietà, Oratorio e Cultura Salesiana, Crescita Personale e Spirituale, Incontri Giovanili Bari, Oratorio per Bambini e Ragazzi, Valori Salesiani Bari',
          openGraph: {
            type: 'website',
            title: 'Contatti'+DEFAULT_TITLE_SUFIX,
            image: 'https://ik.imagekit.io/oratoriofmabari/foto-attivita/666f746f686561646572.jpg',
          },
        })}
      </Helmet>
      <picture>
        <source media="(max-width: 767px)" srcSet="https://ik.imagekit.io/oratoriofmabari/foto-pagine/6f7261746f72696f2d6361737461676e652d6d6f62696c65.png" />
        <source media="(min-width: 768px)" srcSet="https://ik.imagekit.io/oratoriofmabari/foto-pagine/6f7261746f72696f2d6361737461676e652d626967.png" />
        <img loading="lazy" src="https://ik.imagekit.io/oratoriofmabari/foto-pagine/6f7261746f72696f2d6361737461676e652d626967.png" alt="Immagine" />
      </picture>
      <main className="oratorio--pt-3 oratorio--pb-2.5">
        <div className="oratorio--w-full oratorio--mb-3">
          <div className="oratorio--container oratorio--mx-auto *oratorio--text-center +lg:oratorio--text-left">
            <h1 className="oratorio--text-3xl md:oratorio--text-4xl lg:oratorio--text-5xl oratorio--font-serif oratorio--font-bold">Contatti</h1>
          </div>
        </div>
          <section className="oratorio--container">
              <div className="oratorio--grid md:oratorio--grid-cols-2 oratorio--gap-2">
                  <div>
                      <p href="#_" className="oratorio--text-3xl oratorio--font-black oratorio--leading-none oratorio--select-none oratorio--logo oratorio--font-serif">FMA BARI</p>
                      <p className="oratorio--block oratorio--mt-1 oratorio--text-lg oratorio--leading-normal">Lungomare IX Maggio, pal.H 70132 Bari BA</p>
                      <a className="oratorio--block oratorio--mt-1 oratorio--text-lg oratorio--leading-normal" href="tel:+390805343379">tel. 0805343379</a>
                      {/*<a className="oratorio--block oratorio--mt-1 oratorio--text-lg oratorio--leading-normal" href="mailto:info@oratoriofmabari.it">info@oratoriofmabari.it</a>
                      <a className="oratorio--block oratorio--mt-1 oratorio--text-lg oratorio--leading-normal" href="mailto:webmaster@oratoriofmabari.it">webmaster: webmaster@oratoriofmabari.it</a>*/}
                  </div>
                  <div>
                    <div id="mappa" className="oratorio--w-full oratorio--h-[30vh] md:oratorio--h-[50vh] lg:oratorio--h-[60vh]">
                      <MapContainer className="oratorio--w-full oratorio--h-[30vh] md:oratorio--h-[50vh] lg:oratorio--h-[60vh]" center={[41.13724901775989, 16.823130510930604]} zoom={13} scrollWheelZoom={false}>
                          <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@10x.png"
                          />
                          <Marker position={[41.13724901775989, 16.823130510930604]} icon={customIcon}>
                          </Marker>
                        </MapContainer>
                      </div>
                  </div>
              </div>
          </section>
      </main>
      </>
    );
  }

  export default HomePage;
