import React, { useEffect, useState } from 'react';
import { API_URL } from '../../config';
import SantoDelGiornoHome from './SantoGiorno';

const CalendarioHomePage = () => {
    const [eventi, setEventi] = useState([]);

	useEffect(() => {
		fetch(API_URL+'oratoriosangirolamo/v1/eventi?per_page=3&order=desc&orderby=date')
			.then(response => response.json())
			.then(data => setEventi(data));
	}, []);
    return (
        <section className="oratorio--container oratorio--py-2" data-aos="fade-up">
			<h2 className="oratorio--text-2xl lg:oratorio--text-3xl oratorio--font-serif oratorio--font-bold oratorio--mb-2">Calendario</h2>
			<div className="oratorio--grid oratorio--grid-cols-1 lg:oratorio--grid-cols-3 oratorio--gap-3">
				<div className="">
					<div className="oratorio--bg-yellow-700 oratorio--text-white oratorio--text-lg oratorio--py-2 oratorio--font-serif oratorio--font-bold oratorio--uppercase oratorio--text-center"><p>Santo del giorno</p></div>
					<div className="oratorio--w-full oratorio--overflow-hidden oratorio--leading-[0] oratorio--mb-2.5">
						<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 15" preserveAspectRatio="none" style={{ transform: "scale(2)" }}>
							<rect x="1200" height="3.6"></rect>
							<rect height="3.6"></rect>
							<path d="M0,0V3.6H580.08c11,0,19.92,5.09,19.92,13.2,0-8.14,8.88-13.2,19.92-13.2H1200V0Z" className="shape-fill" style={{ fill: "#A16207" }}></path>
						</svg>
					</div>
					<SantoDelGiornoHome></SantoDelGiornoHome>
				</div>
				<div className="lg:oratorio--col-span-2">
					<div className="oratorio--bg-yellow-700 oratorio--text-white oratorio--text-lg oratorio--py-2 oratorio--font-serif oratorio--font-bold oratorio--uppercase oratorio--text-center"><p>EVENTI IN PROGRAMMA</p></div>
					<div className="oratorio--w-full oratorio--overflow-hidden oratorio--leading-[0] oratorio--mb-2.5">
						<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 15" preserveAspectRatio="none">
							<rect x="1200" height="3.6"></rect>
							<rect height="3.6"></rect>
							<path d="M0,0V3.6H580.08c11,0,19.92,5.09,19.92,13.2,0-8.14,8.88-13.2,19.92-13.2H1200V0Z" className="shape-fill" style={{ fill: "#A16207" }}></path>
						</svg>
					</div>
					<main className="oratorio--container oratorio--text-center oratorio--min-h-[25vh] lg:oratorio--min-h-[75vh] oratorio--flex oratorio--items-center oratorio--justify-center">
						<div className="oratorio--text-center">
							<p className="oratorio--text-9xl oratorio--font-serif oratorio--mb-2.5 oratorio--font-bold">OPS</p>
							<p className="oratorio--text-4xl oratorio--font-serif oratorio--mb-5">Non ci sono eventi programmati</p>
						</div>
					</main>
				</div>
			</div>
		</section>
    );
};

export default CalendarioHomePage;
