import React, { Component } from 'react';

class SantoDelGiornoHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primoSanto: {},
      altriSanti: [],
    };
  }

  componentDidMount() {
    fetch('https://www.santodelgiorno.it/santi.json')
      .then(response => response.json())
      .then(data => {
        const primoSanto = data[0];
        const altriSanti = data.slice(1).map(santo => `<strong>${santo.nome}</strong>`);
        
        this.setState({ primoSanto, altriSanti });
      })
      .catch(error => console.error('Errore durante il fetch:', error));
  }

  render() {
    const { primoSanto, altriSanti } = this.state;

    return (
      <div>
        <img src={primoSanto.urlimmagine} alt={primoSanto.nome} width="100%" />
        <p className="oratorio--text-2xl oratorio--font-serif oratorio--font-bold oratorio--inline-block oratorio--mt-2.5">
          {primoSanto.nome}
        </p>
        <p>
          Altri santi del giorno: <span dangerouslySetInnerHTML={{ __html: altriSanti.join(", ").replace(/,([^,]*)$/, ' e $1') }} />
        </p>
      </div>
    );
  }
}

export default SantoDelGiornoHome;