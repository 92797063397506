import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from 'moment';
import 'moment/locale/it';

const FotoCard = ({ foto }) =>  {
    moment.locale('it');
    return (
        <Link to={`/foto/${foto.slug}`} className="oratorio--block oratorio--grid oratorio--grid-cols-3 lg:oratorio--block lg:[grid:none] *oratorio--grid-rows-1 *lg:oratorio--grid-cols-1 *lg:oratorio--grid-rows-2 oratorio--gap-2.5 oratorio--group oratorio--relative oratorio--transition oratorio--ease-out active:oratorio--opacity-75 oratorio--overflow-hidden">
            <div className="oratorio--relative oratorio--inline-block oratorio--mb-2 oratorio--w-full">
                <LazyLoadImage className="oratorio--w-full oratorio--shadow-lg oratorio--block oratorio--transform oratorio--transition oratorio--ease-out *group-hover:oratorio--scale-110 oratorio--object-cover lg:oratorio--h-[35vh]" src={foto.campi.foto_evidenza.url} alt={foto.title.rendered} />
                <div className="oratorio--absolute oratorio--bottom-0 oratorio--left-0 oratorio--py-2 oratorio--px-2.5 oratorio--bg-yellow-700 oratorio--bg-opacity-75 oratorio--text-white"><i className="fa-regular fa-images lg:oratorio--text-lg"></i></div>
            </div>
            <div className="oratorio--col-span-2 *lg:oratorio--row-span-1 *lg:oratorio--col-span-1 oratorio--flex oratorio--flex-col oratorio--justify-center lg:oratorio--flex-none lg:oratorio--justify-normal lg:oratorio--h-max">
                {foto.tematiche && (
                    <div className="oratorio--space-y-2 oratorio--uppercase oratorio--font-bold oratorio--text-sm lg:text-base">
                        {foto.tematiche.map((item, index) => (
                            <span key={index}>{item.name}</span>
                        ))}
                    </div>
                )}
                <h1 className="oratorio--text-2xl oratorio--font-serif oratorio--text-left *oratorio--uppercase oratorio--font-bold" dangerouslySetInnerHTML={{ __html: foto.title.rendered}}></h1>
            </div>
        </Link>
    );
};
export default FotoCard;
