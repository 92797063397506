import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
          main: '#a16207',
        },
    },
    typography: {
      fontFamily: 'Sora',
    },
  });
export default theme;
