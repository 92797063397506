import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";

const CardAttivita = ({ attivita }) => {
    return (
    	<Link to={`/attivita/${attivita.slug}`} className="*oratorio--w-3/4 oratorio--w-full oratorio--snap-start oratorio--snap-always oratorio--shrink-0 oratorio--block oratorio--group oratorio--relative oratorio--transition oratorio--ease-out active:oratorio--opacity-75 oratorio--overflow-hidden">
            <LazyLoadImage
              className="oratorio--w-full oratorio--transform oratorio--transition oratorio--ease-out group-hover:oratorio--scale-110 oratorio--h-full lg:oratorio--h-[35vh] oratorio--object-cover"
              src={attivita.campi.immagine_card.url}
              alt={attivita.title.rendered}
            />
            <div className="oratorio--absolute oratorio--inset-0 oratorio--bg-black oratorio--bg-opacity-25 oratorio--transition oratorio--ease-out group-hover:oratorio--bg-opacity-0"></div>
            <div className="oratorio--p-4 oratorio--flex oratorio--items-center oratorio--justify-center oratorio--absolute oratorio--inset-0">
                <div className="oratorio--py-1 oratorio--px-3 oratorio--bg-yellow-200 oratorio--bg-opacity-95 oratorio--rounded-3xl oratorio--text-lg oratorio--font-semibold oratorio--transition oratorio--ease-out group-hover:oratorio--text-white group-hover:oratorio--bg-yellow-700 oratorio--font-serif">{attivita.title.rendered}</div>
            </div>
        </Link>
      );
    }

export default CardAttivita;
