import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    const dataCorrente = new Date();
    const annoAttuale = dataCorrente.getFullYear();
    return (
        <footer className="oratorio--w-full oratorio--bg-yellow-700">
            <div className="oratorio--container oratorio--py-12 oratorio--mx-auto">
                <div className="oratorio--grid oratorio--grid-cols-2 oratorio--gap-10 oratorio--mb-3 md:oratorio--grid-cols-3 lg:oratorio--grid-cols-12 lg:oratorio--gap-20">
                <div className="oratorio--col-span-3">
                    <a href="#_" className="oratorio--text-xl oratorio--font-black oratorio--leading-none oratorio--text-white oratorio--select-none oratorio--logo">FMA BARI</a>
                    <p className="oratorio--block oratorio--mt-1 oratorio--text-xs oratorio--leading-normal oratorio--text-yellow-100">Lungomare IX Maggio, pal.H 70132 Bari BA</p>
                    <a className="oratorio--block oratorio--mt-1 oratorio--text-xs oratorio--leading-normal oratorio--text-yellow-100" href="tel:+390805343379">tel. 0805343379</a>
                    {/*<a className="oratorio--block oratorio--mt-1 oratorio--text-xs oratorio--leading-normal oratorio--text-yellow-100" href="mailto:info@oratoriofmabari.it">info@oratoriofmabari.it</a>
                    <a className="oratorio--block oratorio--mt-1 oratorio--text-xs oratorio--leading-normal oratorio--text-yellow-100" href="mailto:webmaster@oratoriofmabari.it">webmaster: webmaster@oratoriofmabari.it</a>*/}
                    <p className="oratorio--block oratorio--my-5 oratorio--text-xs oratorio--leading-normal oratorio--text-yellow-100">La comunità delle Figlie di Maria Ausiliatrice nel quartiere San Girolamo a Bari è una vera e propria famiglia religiosa.</p>
                </div>
                <div className="oratorio--col-span-3">
                    <span className="oratorio--inline-flex oratorio--justify-center oratorio--w-full oratorio--mt-4 oratorio--space-x-5 oratorio--sm:ml-auto oratorio--sm:mt-0 oratorio--sm:justify-end">
                    <a href="#" className="oratorio--text-yellow-100 hover:oratorio--text-white">
                        <span className="oratorio--sr-only">Facebook</span>
                    </a>
                    <a href="#" className="oratorio--text-yellow-100 hover:oratorio--text-white">
                        <span className="oratorio--sr-only">Instagram</span>
                    </a>
                    <a href="#" className="oratorio--text-yellow-100 hover:oratorio--text-white">
                        <span className="oratorio--sr-only">Twitter</span>
                    </a>
                    <a href="#" className="oratorio--text-yellow-100 hover:oratorio--text-white">
                        <span className="oratorio--sr-only">GitHub</span>
                    </a>
                    <a href="#" className="oratorio--text-yellow-100 hover:oratorio--text-white">
                        <span className="oratorio--sr-only">Dribbble</span>
                    </a>
                    </span>
                </div>
                </div>
                <div className="oratorio--flex oratorio--flex-col oratorio--items-start oratorio--justify-between oratorio--pt-10 oratorio--mt-10 oratorio--border-t oratorio--border-yellow-500 md:oratorio--flex-row md:oratorio--items-center">
                    <p className="oratorio--mb-6 oratorio--text-sm oratorio--text-left oratorio--text-yellow-200 md:oratorio--mb-0">© {annoAttuale} Oratorio Figlie di Maria Ausiliatrice - Quartiere San Girolamo Bari - Tutti i diritti sono riservati<br/>sviluppato da <a href="https://ottomanelliandrea.it/">Andrea Ottomanelli</a></p>
                <div className="oratorio--flex oratorio--items-start oratorio--justify-start oratorio--space-x-6 md:oratorio--items-center md:oratorio--justify-center oratorio--overflow-x-auto">
                    <Link to={`/dichiarazione-di-accessibilita/`} className="oratorio--flex-none oratorio--text-sm oratorio--text-yellow-200 oratorio--transition hover:oratorio--text-white">Dichiarazione di accessibilità</Link>
                    <Link to={`/privacy/`} className="oratorio--flex-none oratorio--text-sm oratorio--text-yellow-200 oratorio--transition hover:oratorio--text-white">Privacy Policy</Link>
                    <a className="oratorio--flex-none oratorio--text-sm oratorio--text-yellow-200 oratorio--transition hover:oratorio--text-white" data-cc="show-preferencesModal">Preferenze</a>
                </div>
                </div>
            </div>
            </footer>
    );
};

export default Footer;
