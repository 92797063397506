import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from 'moment';
import 'moment/locale/it';

const CardNotizia = ({ notizia, azioneclick = () => {} }) =>  {
    moment.locale('it');
    return (
        <Link onClick={azioneclick} to={`/notizia/${notizia.slug}`} className="oratorio--block oratorio--grid oratorio--grid-cols-3 lg:oratorio--block lg:[grid:none] *oratorio--grid-rows-1 *lg:oratorio--grid-cols-1 *lg:oratorio--grid-rows-2 oratorio--gap-2.5 oratorio--group oratorio--relative oratorio--transition oratorio--ease-out active:oratorio--opacity-75 oratorio--overflow-hidden">
           <LazyLoadImage
                    className="oratorio--shadow-lg oratorio--block lg:oratorio--w-full lg:oratorio--h-[35vh] oratorio--transform oratorio--transition oratorio--ease-out *group-hover:oratorio--scale-110 oratorio--object-cover oratorio--rounded-lg"
                    src={notizia["_embedded"]["wp:featuredmedia"][0].source_url}
                    alt={notizia.title.rendered}
                />
                <div className="oratorio--mt-1.5 oratorio--col-span-2 *lg:oratorio--row-span-1 *lg:oratorio--col-span-1 oratorio--flex oratorio--flex-col oratorio--justify-center lg:oratorio--flex-none lg:oratorio--justify-normal lg:oratorio--h-max">
                    <div className="oratorio--space-y-2 oratorio--uppercase oratorio--font-bold oratorio--text-sm lg:text-base">
                        {notizia.tematiche.map((item, index) => (
                            <span key={index}>{item.name}</span>
                        ))}
                    </div>
                    <h1 className="oratorio--text-2xl oratorio--font-serif oratorio--mb-2 oratorio--text-left *oratorio--uppercase oratorio--font-bold" dangerouslySetInnerHTML={{ __html: notizia.title.rendered}}></h1>
                    <p className="*oratorio--mb-4 oratorio--uppercase oratorio--text-left oratorio--text-sm"><strong>{moment(notizia["date"]).format('DD MMMM YYYY')}</strong> {moment(notizia["date"]).format('HH:ss')}</p>
                </div>
        </Link>
    );
};
export default CardNotizia;
