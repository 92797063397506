import React from 'react';
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';
import Helmet from 'react-helmet';
import { DEFAULT_TITLE_SUFIX } from '../config';

function ChiSiamo() {
    return (
      <>
      <Helmet>
        {generateHeadTags({
          title: 'Chi siamo'+DEFAULT_TITLE_SUFIX,
          description: 'Le Figlie di Maria Ausiliatrice, nel solco dello spirito salesiano e seguendo l&#039;esempio luminoso di Don Bosco e di Madre Maria Mazzarello, sono attive nella comunità del quartiere periferico di San Girolamo a Bari. La nostra missione è centrata sulla formazione dei bambini e il recupero dei giovani, con l&#039;obiettivo di farne &quot;Buoni cristiani e onesti cittadini&quot;, seguendo l&#039;ispirazione di Don Bosco.',
          keywords: 'Oratorio San Girolamo Bari, Figlie di Maria Ausiliatrice, Salesiane Don Bosco Maria Ausiliatrice, Attività Oratorio Bari, Comunità Salesiana Bari, Educazione Salesiana, Eventi Oratorio San Girolamo, Iniziative Educativo-Ricreative, Formazione Spirituale Bari, Servizio Giovanile Don Bosco, Impegno Sociale e Solidarietà, Oratorio e Cultura Salesiana, Crescita Personale e Spirituale, Incontri Giovanili Bari, Oratorio per Bambini e Ragazzi, Valori Salesiani Bari',
          openGraph: {
            type: 'website',
            title: 'Chi siamo',
            image: 'https://ik.imagekit.io/oratoriofmabari/foto-pagine/6f7261746f72696f2d6361737461676e652d626967.png',
          },
        })}
      </Helmet>
      <picture>
        <source media="(max-width: 767px)" srcSet="https://ik.imagekit.io/oratoriofmabari/foto-pagine/6f7261746f72696f2d6361737461676e652d6d6f62696c65.png" />
        <source media="(min-width: 768px)" srcSet="https://ik.imagekit.io/oratoriofmabari/foto-pagine/6f7261746f72696f2d6361737461676e652d626967.png" />
        <img loading="lazy" src="https://ik.imagekit.io/oratoriofmabari/foto-pagine/6f7261746f72696f2d6361737461676e652d626967.png" alt="Immagine" />
      </picture><main className="oratorio--pt-3 oratorio--pb-2.5">
          <div className="oratorio--w-full oratorio--mb-3">
            <div className="oratorio--container oratorio--mx-auto *oratorio--text-center +lg:oratorio--text-left">
              <h1 className="oratorio--text-3xl md:oratorio--text-4xl lg:oratorio--text-5xl oratorio--font-serif oratorio--font-bold">Chi siamo</h1>
            </div>
          </div>
          <section className="oratorio--container">
            <div className="oratorio--prose lg:oratorio--prose-lg oratorio--max-w-none">
              <p>Le Figlie di Maria Ausiliatrice, nel solco dello spirito salesiano e seguendo l'esempio luminoso di Don Bosco e di Madre Maria Mazzarello, sono attive nella comunità del quartiere periferico di San Girolamo a Bari. La loro missione è centrata sulla formazione dei bambini e il recupero dei giovani, con l'obiettivo di farne "Buoni cristiani e onesti cittadini", seguendo l'ispirazione di Don Bosco.</p>
              <p>Nella casa di Bari, le Figlie di Maria Ausiliatrice, con l'aiuto prezioso delle ex allieve/i e dei numerosi volontari che animano l'oratorio, si dedicano a una vasta gamma di attività ludiche e socio-formative. Tra queste attività, si contano doposcuola, corsi di cucito, cucina, teatro, danza, musica e varie discipline sportive. La presenza costante di volontari e ex allieve/i testimonia l'importanza della collaborazione comunitaria nel promuovere il benessere e lo sviluppo dei giovani.</p>
              <p>La solidarietà con le famiglie in difficoltà del territorio è espressa con la distribuzione di generi alimentari e di vestiario. Le Figlie di Maria Ausiliatrice prestano inoltre una costante assistenza spirituale e psicologica ai giovani reclusi nell'Istituto Penale per Minori "FORNELLI". La loro presenza si estende anche alle case di cura "VILLA GIOVANNA" e "VITTORIO EMANUELE", dove offrono conforto religioso agli anziani.</p>
              <p>La comunità delle Figlie di Maria Ausiliatrice nel quartiere San Girolamo a Bari è una vera e propria famiglia religiosa. Radicata nell'eredità spirituale di San Giovanni Bosco e nella creatività fedele di Santa Maria Domenica Mazzarello, continua a diffondere il Vangelo e a predicare l'insegnamento di Don Bosco nei luoghi più difficili e marginalizzati. La missione delle Figlie è improntata all'educazione e alla crescita integrale dei giovani e dei fanciulli, seguendo le Costituzioni delle Figlie di Maria Ausiliatrice.</p>
              <p>Le Figlie di Maria Ausiliatrice a San Girolamo, Bari, incarnano il dono dello Spirito Santo e l'intervento diretto di Maria nella fondazione dell'Istituto da parte di San Giovanni Bosco. Sotto l'ispirazione della carità di Cristo Buon Pastore, hanno ricevuto un forte impulso missionario. La loro azione missionaria è permeata dalla carità che sgorga dal Cuore del Buon Pastore, invitando tutti a scoprire e lasciarsi affascinare da questo "donarsi tutto a tutti".</p>
            </div>
          </section>
        </main></>
    );
  }

  export default ChiSiamo;